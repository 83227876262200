const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const DELETE = "DELETE";

const base = "/";

class HTTPError extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

const fetchJSON = async (
  url,
  method = GET,
  data = {},
  retries = 4,
  delay = 1500
) => {
  const params = {
    method,
    credentials: "include" as RequestCredentials,
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (method !== GET && data) {
    params["body"] = JSON.stringify(data);
  }
  const response = await fetch(url, params);

  if (!response.ok) {
    // If the status is 500 and the URL matches "validate-token", retry
    if (
      response.status === 500 &&
      url.includes("validate-token") &&
      retries > 0
    ) {
      console.warn(
        `500 error on ${url}, retrying in ${delay}ms... (${retries} retries left)`
      );
      await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
      return fetchJSON(url, PUT, {}, retries - 1, delay * 2); // Retry with exponential backoff
    }

    // throw new Error(`Response status: ${response.status}`);
  }
  const json = await response.json();
  if (response.status >= 300) {
    throw new HTTPError(
      json.message || `Error making call ${url}`,
      response.status
    );
  }
  return json;
};

const create = (base) => {
  return {
    httpGet: (url, data) => {
      if (data) {
        const query = Object.keys(data)
          .map((key) => {
            const v = data[key];
            if (v !== undefined && v !== null) {
              return key + "=" + v;
            }
            return null;
          })
          .filter((v) => v !== null)
          .join("&");

        if (url.indexOf("?") > -1) {
          url += "&" + query;
        } else {
          url += "?" + query;
        }
      }

      return fetchJSON(base + url, GET);
    },
    httpDelete: (url) => {
      return fetchJSON(base + url, DELETE);
    },
    httpPost: (url, data = {}) => {
      return fetchJSON(base + url, POST, data);
    },
    httpPut: (url, data) => {
      return fetchJSON(base + url, PUT, data);
    },
  };
};

const parseQuery = (search) => {
  const query = {};
  if (search.length) {
    search = search.substring(1);
    const parts = search.split("&");
    parts.forEach((p) => {
      const pair = p.split("=");
      query[pair[0]] = pair[1];
    });
  }
  return query;
};

const getAPIServer = () => {
  if (window.location.host.includes("localhost")) {
    return "http://localhost:8080";
    // return "https://stageapi.prescient.properties";
    // return "https://api.prescient.properties";
  } else {
    if (
      window.location.host === "stagevoice.prescient.properties" ||
      window.location.host === "stageadmin.prescient.properties"
    ) {
      return "https://stageapi.prescient.properties";
    }
  }
  // Default to Production
  return "https://api.prescient.properties";
};

const { httpGet, httpDelete, httpPost, httpPut } = create("/");

export {
  httpGet,
  httpDelete,
  httpPost,
  httpPut,
  create,
  parseQuery,
  getAPIServer,
};
