import {
  MdHome,
  MdDataUsage,
  MdSchedule,
  MdDateRange,
  MdAccountCircle,
  MdLeaderboard,
  MdStore,
  MdLogout,
  MdSearch,
  MdOutline3P,
  MdOutlineLibraryBooks,
  MdOutlineStackedBarChart,
  MdQuestionAnswer,
} from "react-icons/md";
import {
  RiDatabase2Fill,
  RiQuestionFill,
  RiBarChartHorizontalFill,
} from "react-icons/ri";
import { FaDotCircle, FaRegMap } from "react-icons/fa";
import {
  BiChevronUp,
  BiChevronDown,
  BiChevronLeft,
  BiChevronRight,
  BiNetworkChart,
} from "react-icons/bi";
import {
  BsPeopleFill,
  BsGenderAmbiguous,
  BsGraphUp,
  BsSliders,
  BsBuildings,
  BsSuitcaseLgFill,
} from "react-icons/bs";

import { FiSmile } from "react-icons/fi";

import { FaBalanceScale } from "react-icons/fa";

export default {
  House: MdHome,
  Donut: MdDataUsage,
  Clock: MdSchedule,
  Calendar: MdDateRange,
  Account: MdAccountCircle,
  BarGraph: MdLeaderboard,
  StackedBarGraph: MdOutlineStackedBarChart,
  BarGraphHorizontal: RiBarChartHorizontalFill,
  LineGraph: BsGraphUp,
  Store: MdStore,
  Database: RiDatabase2Fill,
  Logout: MdLogout,
  CircleEmpty: FaDotCircle,
  ChevronUp: BiChevronUp,
  ChevronDown: BiChevronDown,
  ChevronLeft: BiChevronLeft,
  ChevronRight: BiChevronRight,
  Gender: BsGenderAmbiguous,
  People: BsPeopleFill,
  Network: BiNetworkChart,
  Smile: FiSmile,
  Comment: MdOutline3P,
  Slider: BsSliders,
  Search: MdSearch,
  Scale: FaBalanceScale,
  Buildings: BsBuildings,
  Library: MdOutlineLibraryBooks,
  Map: FaRegMap,
  Question: RiQuestionFill,
  Portfolio: BsSuitcaseLgFill,
  CustomQuestions: MdQuestionAnswer,
};
