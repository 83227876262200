import React from "react";
import { create as createHttp } from "../utils/net";

import { sleep } from "../utils";
// import {doTimes} from

const wrap = (fn, redirect) => {
  return async (...args) => {
    try {
      const res = await fn(...args);
      return res;
    } catch (e) {
      if (e.status === 401) {
        redirect("/login");
      } else {
        console.log("Error caught");
        console.log(e);
      }
    }
  };
};

const formatDate = (date: Date) => {
  //date.toISOString() on its own converts to UTC, so we need some timezone voodoo to keep the right date
  var isoDateTime = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
  return isoDateTime.split("T")[0];
};

const api = {
  configure({ server, redirect }) {
    const { httpPost, httpGet, httpPut, httpDelete } = createHttp(server);
    this.httpGet = wrap(httpGet, redirect);
    this.httpPost = wrap(httpPost, redirect);
    this.httpPut = wrap(httpPut, redirect);
    this.httpDelete = wrap(httpDelete, redirect);
  },

  // AUTH

  exchangeToken({ code }) {
    return this.httpPost("/auth/exchange-token", { code });
  },

  validateToken() {
    return this.httpPut("/auth/validate-token");
  },

  logout() {
    return this.httpDelete("/auth/logout");
  },

  // ACCOUNT

  async account() {
    return this.httpGet("/account");
  },

  // REPORTS

  async nps({
    accountId,
    venueIds,
    benchmark,
    from,
    to,
    isPortfolioView = false,
  }: {
    accountId: string;
    venueIds: string[];
    benchmark?: string;
    from: Date;
    to: Date;
    isPortfolioView?: boolean;
  }) {
    const res = await this.httpGet(`/reports/nps`, {
      from: formatDate(from),
      to: formatDate(to),
      benchmark: benchmark,
      accountId,
      venueIds: venueIds.join(","),
      isPortfolioView,
    });
    return res;
  },

  /*
  npsQuestions({
    accountId,
    venueId,
  }: {
    accountId: string;
    venueId: string;
  }) {
    return this.httpGet(`/reports/nps/questions/${accountId}/${venueId}`);
  },
  */

  statistics({
    accountId,
    venueIds,
    benchmark,
    from,
    to,
    isPortfolioView = false,
  }: {
    accountId: string;
    venueIds: string[];
    benchmark?: string;
    from: Date;
    to: Date;
    isPortfolioView?: boolean;
  }) {
    return this.httpGet(`/reports/stats`, {
      from: formatDate(from),
      to: formatDate(to),
      benchmark: benchmark,
      accountId,
      venueIds: venueIds.join(","),
      isPortfolioView,
    });
  },

  touchpoints({
    accountId,
    venueIds,
    benchmark,
    from,
    to,
    isPortfolioView = false,
  }: {
    accountId: string;
    venueIds: string[];
    benchmark?: string;
    from: Date;
    to: Date;
    isPortfolioView?: boolean;
  }) {
    return this.httpGet(`/reports/touchpoints`, {
      from: formatDate(from),
      to: formatDate(to),
      benchmark: benchmark,
      accountId,
      venueIds: venueIds.join(","),
      isPortfolioView,
    });
  },

  customQuestions({
    accountId,
    venueId,
    from,
    to,
    historical = false,
  }: {
    accountId: string;
    venueId: string[];
    from: Date;
    to: Date;
    historical?: boolean;
  }) {
    return this.httpGet(`/reports/customQuestions/${accountId}/${venueId}`, {
      from: formatDate(from),
      to: formatDate(to),
      historical,
    });
  },

  touchpointQuestions({
    accountId,
    venueId,
  }: {
    accountId: string;
    venueId: string;
  }) {
    return this.httpGet(
      `/reports/touchpoints/questions/${accountId}/${venueId}`
    );
  },

  touchpointDetail({
    accountId,
    venueId,
    benchmark,
    touchpointId,
    from,
    to,
  }: {
    accountId: string;
    venueId: string;
    benchmark: string;
    touchpointId: string;
    from: Date;
    to: Date;
  }) {
    return this.httpGet(
      `/reports/touchpoints/deepdive/${accountId}/${venueId}`,
      {
        from: formatDate(from),
        to: formatDate(to),
        touchpointId,
        benchmark: benchmark,
      }
    );
  },

  themes({
    accountId,
    venueIds,
    benchmark,
    from,
    to,
    isPortfolioView = false,
  }: {
    accountId: string;
    venueIds: string[];
    benchmark?: string;
    from: Date;
    to: Date;
    isPortfolioView?: boolean;
  }) {
    return this.httpGet(`/reports/themes`, {
      from: formatDate(from),
      to: formatDate(to),
      benchmark,
      accountId,
      venueIds: venueIds.join(","),
      isPortfolioView,
    });
  },

  suggestions({
    accountId,
    venueId,
    from,
    to,
  }: {
    accountId: string;
    venueId: string;
    from: Date;
    to: Date;
  }) {
    return this.httpGet(`/reports/suggestions/${accountId}/${venueId}`, {
      from: formatDate(from),
      to: formatDate(to),
    });
  },

  async comments({
    accountId,
    venueId,
    from,
    to,
    filter,
  }: {
    accountId: string;
    venueId: string;
    from: Date;
    to: Date;
    filter?: CommentFilter;
  }) {
    return await this.httpGet(`/reports/comments/${accountId}/${venueId}`, {
      from: formatDate(from),
      to: formatDate(to),
    });
  },

  async improvements({
    accountId,
    venueId,
    touchpointId,
    from,
    to,
  }: {
    accountId: string;
    venueId: string;
    touchpointId: string;
    from: Date;
    to: Date;
  }) {
    return this.httpGet(
      `/reports/touchpoints/improvements/${accountId}/${venueId}/${touchpointId}`,
      {
        from: formatDate(from),
        to: formatDate(to),
      }
    );
  },

  async commentAnalysis({
    accountId,
    venueId,
    touchpointId,
    to,
  }: {
    accountId: string;
    venueId: string;
    touchpointId: string;
    to: Date;
  }) {
    return this.httpGet(
      `/reports/touchpoints/commentanalysis/${accountId}/${venueId}/${touchpointId}`,
      {
        to: formatDate(to),
      }
    );
  },

  async profiles({
    accountId,
    venueId,
    touchpointId,
    from,
    to,
  }: {
    accountId: string;
    venueId: string;
    touchpointId: string;
    from: Date;
    to: Date;
  }) {
    const result = await this.httpGet(
      `/reports/behaviours/${accountId}/${venueId}`,
      {
        from: formatDate(from),
        to: formatDate(to),
      }
    );

    return result;
  },

  admin: {
    async accounts() {
      return (api as any).httpGet("/admin/accounts");
    },
    async touchpoints() {
      return (api as any).httpGet("/admin/touchpoints");
    },
    async benchmarks() {
      return (api as any).httpGet("/admin/benchmarks");
    },
    async benchmarkValues(id) {
      return (api as any).httpGet(`/admin/benchmarks/${id}/values`);
    },
    async staticScores() {
      return (api as any).httpGet("/admin/static-scores");
    },
  },
};

type CommentFilter = {
  touchpointId?: string;
};

const useAPI = (apiPromise, successCallback, errorCallback = null) => {
  React.useEffect(() => {
    const awaitAPI = async () => {
      try {
        const result = await apiPromise;
        successCallback(result);
      } catch (e) {
        if (errorCallback) {
          errorCallback(e);
        }
      }
    };
    awaitAPI();
  }, []);
};

export { useAPI };

export default api;
