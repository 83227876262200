import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { globalStyles } from "@pp/lib/ui/theme";
import { HStack, Loader } from "../components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import api from "@pp/lib/net/api-service";
import { parseQuery } from "../../utils/net";
import auth from "@pp/lib/utils/auth";
import { getAPIServer } from "@pp/lib/utils/net";

const getAuthorisedUser = async () => {
  const query = parseQuery(window.location.search);

  try {
    let user = null;
    if (query && query["code"]) {
      const code = query["code"];
      user = await api.exchangeToken({ code });
    } else {
      user = await api.validateToken();
    }
    if (user) {
      const accounts = await api.account();
      var transformedAccounts = accounts.Accounts.map((x) => {
        return {
          id: x.AccountId,
          shortId: x.ShortId,
          name: x.Name,
          venues: x.Venues.map((v) => {
            return {
              id: v.Id,
              name: v.Name,
              surveyId: v.SurveyId,
              benchmarks: (v.Benchmarks || []).map(({ Id, Label }) => {
                return { id: Id, label: Label };
              }),
              uiConfig: v.UIConfig,
            };
          }),
          uiConfig: x.UIConfig,
          venueGroups: x.VenueGroups,
          portfolioBenchmarkIds: x.PortfolioBenchmarkIds,
        };
      });
      const { id, email, name } = user.data;
      return { id, email, name, accounts: transformedAccounts };
    } else {
      return null;
    }
  } catch (e) {
    console.log(e);
    return null;
  }
};

const AppInitialiser = ({
  app,
}: {
  app: React.ComponentType<{
    user: any;
  }>;
}) => {
  globalStyles();
  return (
    <Router>
      <InitApi>
        <HStack
          css={{
            size: "$full",
          }}
        >
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="*" element={<ValidateUser app={app} />} />
          </Routes>
        </HStack>
      </InitApi>
    </Router>
  );
};

const InitApi = ({ children = null }) => {
  api.configure({ server: getAPIServer(), redirect: useNavigate() });
  return <>{children}</>;
};

const Login = () => {
  window.location.href = auth.getLoginUrl();
  return null;
};

const Logout = () => {
  useEffect(() => {
    const logout = async () => {
      await api.logout();
      window.location.href = auth.getLogoutUrl();
    };
    logout();
  }, []);
  return null;
};

const ValidateUser = ({ app }) => {
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    const makeCall = async () => {
      const user = await getAuthorisedUser();
      setCurrentUser(user);
    };
    makeCall();
  }, []);

  return (
    <Loader
      css={{ width: "100%", height: "100%" }}
      data={currentUser}
      content={() => {
        return React.createElement(app, { user: currentUser });
      }}
    />
  );
};

export default AppInitialiser;
