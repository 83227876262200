import React from "react";
import { HStack, VStack, Box } from "./index";

const Select = ({ trigger, content, css = {} }) => {
  React.useEffect(() => {
    if (document?.body) {
      document.body.addEventListener("click", (event) => {
        if (
          ref &&
          ref.current &&
          (ref.current == event.target || ref.current.contains(event.target))
        ) {
          return;
        }
        setOpen(false);
      });
    }
  }, []);

  const ref = React.useRef();

  const [open, setOpen] = React.useState(false);
  return (
    <VStack ref={ref} css={css}>
      <Trigger
        onClick={() => {
          setOpen(!open);
        }}
        trigger={trigger}
      />
      <Content
        content={content}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </VStack>
  );
};

const Trigger = ({ trigger, onClick }) => {
  return <HStack onClick={onClick}>{trigger()}</HStack>;
};

const Content = ({ content, open, onClose, css = null }) => {
  return (
    <Box css={{ position: "absolute", display: open ? "block" : "none", ...css }}>
      {content(onClose)}
    </Box>
  );
};

export default Select;
